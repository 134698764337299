exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-academy-coaches-js": () => import("./../../../src/pages/academy/coaches.js" /* webpackChunkName: "component---src-pages-academy-coaches-js" */),
  "component---src-pages-academy-js": () => import("./../../../src/pages/academy.js" /* webpackChunkName: "component---src-pages-academy-js" */),
  "component---src-pages-academy-parents-js": () => import("./../../../src/pages/academy/parents.js" /* webpackChunkName: "component---src-pages-academy-parents-js" */),
  "component---src-pages-academy-players-js": () => import("./../../../src/pages/academy/players.js" /* webpackChunkName: "component---src-pages-academy-players-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-terms-of-service-js": () => import("./../../../src/pages/privacy-policy-terms-of-service.js" /* webpackChunkName: "component---src-pages-privacy-policy-terms-of-service-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-templates-testimonials-template-js": () => import("./../../../src/templates/TestimonialsTemplate.js" /* webpackChunkName: "component---src-templates-testimonials-template-js" */)
}

